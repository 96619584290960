import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
//import products from "./data/products.json";
import ProductList from "./data/products/ProductList";
import App from "./App";
import "./assets/scss/style.scss";
import "./assets/css/dev-custom.css";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import {API_URL} from "./global";
import { fetchToWishlist } from "./redux/actions/wishlistActions";


import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

ProductList().then(function(res){ 
	//console.log('Product: ',res.products);
	// fetch products from json file
	//store.dispatch(fetchProducts(products));
	store.dispatch(fetchProducts(res.products));
} );


async function GetWishlistVal(){
	const item = new FormData();
	var user = JSON.parse(localStorage.getItem('user-info'));
	item.append('user', user.user_id);
	
	axios({
		method: "post",
		url: API_URL+'/api/get-wishlist-buyer',
		data: item,
		processData: false,
		contentType: false,
	})
	.then(res => {
		store.dispatch(fetchToWishlist(res.data));
	});
}
if( localStorage.getItem('user-info') ){
	GetWishlistVal();
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
